import React from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { TencerRoutes } from './tencer-routes';

const LoginRouter = React.lazy(() => import('app/shared/tencer-routing/tencer-routers/login-router'));
const AppRouter = React.lazy(() => import('app/shared/tencer-routing/tencer-routers/home-router'));

export const AppRouting: React.FC = () => {
    const customHistory = createBrowserHistory();

    return (
        <React.Suspense fallback={null}>
            <Router history={customHistory}>
                <Switch>
                    <Route path={TencerRoutes.LOGIN} component={LoginRouter} />
                    <Route exact path={TencerRoutes.HOME} component={AppRouter} />
                    <Redirect to="/" />
                </Switch>
            </Router>
        </React.Suspense>
    );
};
