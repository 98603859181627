import { BannerPage } from 'app/modules/settings/domain/entity/BannerPage';
import { TranslationArray } from 'app/modules/settings/domain/entity/TranslationArray';
import { BannerPageName } from 'app/modules/settings/domain/valueObject/BannerPageName';

export class BannerPageArray {

    private _pages: BannerPage[];

    public constructor() {
        this._pages = [];
    }

    get bannerPages(): BannerPage[] {
        return this._pages;
    }

    public setBannerPages(value: BannerPage[]): void {
        this._pages = value;
    }

    public addBannerPage(value: BannerPage): void {
        this._pages?.push(value);
    }

    public addBannerPages(values: BannerPage[]): void {
        values.map((bannerPage):void => this.addBannerPage(bannerPage));
    }

    public getLength(): number {
        return this._pages?.length;
    }

    public getBannerPage(index:number):BannerPage | null {
        return this._pages[index];
    }

    public getBannerPageTranslations(pageName: BannerPageName):TranslationArray {
        let translations = new TranslationArray();

        this._pages.forEach((item) => {
            if (item.name.value === pageName.value) {
                translations = item.translations;
            }
        });

        return translations;
    }

    public getBannerId(pageName: BannerPageName):string {
        let bannerId: string = '';

        this._pages.forEach((item) => {
            if (item.name.value === pageName.value) {
                bannerId = item.id.value;
            }
        });

        return bannerId;
    }

}
