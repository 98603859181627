export interface SettingResponse{
    id: string
    origin: number
    name: SettingNameResponse
    value: number
}

export enum SettingNameResponse {
    BIG_RESERVE_UNIT= 'big_reserve_unit',
    BIG_RESERVE_M2= 'big_reserve_m2',
    STOCK_RESERVATION_TIME= 'stock_reservation_time',
    STOCK_RESERVATION_TIME_CHECKOUT= 'stock_reservation_time_checkout',
    CUT_OFF_HOUR= 'shipping_appointment_cut_off',
    CARRIER_PICKUP_BEFORE_CUT_OFF_HOUR= 'shipping_appointment_before_cut_off',
    CARRIER_PICKUP_AFTER_CUT_OFF_HOUR= 'shipping_appointment_after_cut_off',
    CARRIER_INSURANCE= 'shipping_insurance',
    PICK_HANDLE_PRICE= 'pick_handle_price',
    PICK_HANDLE_ACCOUNT= 'pick_handle_account',
}
