import { Setting } from 'app/modules/settings/domain/entity/Setting';
import { SettingName, SettingNameValue } from 'app/modules/settings/domain/valueObject/SettingName';

export class SettingsArray {

    private _settings: Setting[];

    public constructor() {
        this._settings = [];
    }

    get settings(): Setting[] {
        return this._settings;
    }

    get basicCartSettings(): Setting[] {
        return this._settings.filter((setting) => {
            return [
                SettingNameValue.BIG_RESERVE_M2,
                SettingNameValue.BIG_RESERVE_UNIT,
                SettingNameValue.STOCK_RESERVE_TIME,
                SettingNameValue.STOCK_RESERVE_TIME_CHECKOUT,
            ].includes(setting.name.value);
        });
    }

    public setSettings(value: Setting[]): void {
        this._settings = value;
    }

    public addSetting(value: Setting): void {
        this._settings?.push(value);
    }

    public addSettings(values: Setting[]): void {
        values.map((setting):void => this.addSetting(setting));
    }

    public getLength(): number {
        return this._settings?.length;
    }

    public getSetting(index:number):Setting | null {
        return this._settings[index];
    }

    public findByName(name: SettingName): Setting {
        const settingByName = this._settings.find((setting) => setting.name.value === name.value);

        if (!settingByName) {
            throw new Error('Setting can not be undefined');
        }
        return settingByName;
    }

}
