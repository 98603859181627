import { LoginRepositoryInstance } from './login-repository';
import { Auth } from '../../../domain/auth';
import { LoginMapper } from '../data-mapper/login.mapper';
import { TencerAuthServiceInstance } from '../../../../shared/tencer-routing/tencer-auth-route/tencer-auth-service';

export const Refresh = async ():Promise<void> => {
    const refreshToken = localStorage.getItem('refresh_token');

    const response = await LoginRepositoryInstance.refreshToken(refreshToken!)
        .then((res) => {
            const refreshTokenResponse:Auth = LoginMapper.toDomain(res.data);
            TencerAuthServiceInstance.authenticate(refreshTokenResponse.getToken!);
            TencerAuthServiceInstance.authenticateRefreshToken(refreshTokenResponse.getRefreshToken!);
        });
    return response;
};
