import { BannerPageId } from 'app/modules/settings/domain/valueObject/BannerPageId';
import { BannerPageName } from 'app/modules/settings/domain/valueObject/BannerPageName';
import { BannerPageOrigin } from 'app/modules/settings/domain/valueObject/BannerPageOrigin';
import { TranslationArray } from 'app/modules/settings/domain/entity/TranslationArray';

export class BannerPage {

    private readonly _id: BannerPageId;
    private readonly _origin: BannerPageOrigin;
    private readonly _name: BannerPageName;
    private readonly _translations: TranslationArray;

    public constructor(
        id:BannerPageId,
        origin:BannerPageOrigin,
        name:BannerPageName,
        translations:TranslationArray,

    ) {
        this._id = id;
        this._origin = origin;
        this._name = name;
        this._translations = translations;

    }

    public get id(): BannerPageId {
        return this._id;
    }

    public get origin(): BannerPageOrigin {
        return this._origin;
    }

    public get name(): BannerPageName {
        return this._name;
    }

    public get translations(): TranslationArray {
        return this._translations;
    }

}
