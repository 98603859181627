import { AxiosResponse } from 'axios';
import { SettingResponse } from 'app/modules/settings/infrastructure/dto/response/SettingResponse';
import { AxiosInstance } from 'app/shared/infrastructure/api/web-service/axios-instance';
import { getToken } from 'app/shared/helpers/config';
import { PutSettingItemRequest } from 'app/modules/settings/infrastructure/dto/request/PutSettingItemRequest';
import { PatchShipmentAppointmentRequest } from 'app/modules/settings/infrastructure/dto/request/PatchShipmentAppointmentRequest';
import { PatchPickHandleRequest } from 'app/modules/settings/infrastructure/dto/request/PatchPickHandleRequest';
import { BannerPageResponse } from 'app/modules/settings/infrastructure/dto/response/BannerPageResponse';
import { PutBannerItemRequest } from 'app/modules/settings/infrastructure/dto/request/PutBannerItemRequest';
import { WarehouseContactResponse } from 'app/modules/settings/infrastructure/dto/response/WarehouseContactResponse';
import { PatchWarehouseContactRequest } from 'app/modules/settings/infrastructure/dto/request/PatchWarehouseContactRequest';

export interface TencerAdminApiClient {
    getSettings: () => Promise<AxiosResponse<SettingResponse[]>>
    putSettingItem: (request: PutSettingItemRequest) => Promise<AxiosResponse<string>>
    patchShipmentAppointment: (request: PatchShipmentAppointmentRequest) => Promise<AxiosResponse<void>>
}

export class AxiosTencerAdminApiClient implements TencerAdminApiClient {

    public constructor() {
    }

    public async getSettings(): Promise<AxiosResponse<SettingResponse[]>> {
        const endpoint = '/api/admin/settings/';
        return AxiosInstance.get<undefined,
        AxiosResponse <SettingResponse[]>>(endpoint, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async getWarehouseContacts(): Promise<AxiosResponse<WarehouseContactResponse[]>> {
        const endpoint = '/api/admin/settings/warehouse-contacts';
        return AxiosInstance.get<undefined,
        AxiosResponse <WarehouseContactResponse[]>>(endpoint, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async putSettingItem(request: PutSettingItemRequest): Promise<AxiosResponse<string>> {
        const endpoint = '/api/admin/settings/';
        return AxiosInstance.put<undefined,
        AxiosResponse <string>>(endpoint, request, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async patchShipmentAppointment(request: PatchShipmentAppointmentRequest): Promise<AxiosResponse<void>> {
        const endpoint = '/api/admin/settings/shipment-appointment';
        return AxiosInstance.patch(endpoint, request, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async patchPickHandle(request: PatchPickHandleRequest): Promise<AxiosResponse<void>> {
        const endpoint = '/api/admin/settings/pick-handle';
        return AxiosInstance.patch(endpoint, request, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async patchWarehouseContact(request: PatchWarehouseContactRequest): Promise<AxiosResponse<void>> {
        const endpoint = `/api/admin/settings/warehouse-contacts/${request.code}`;
        return AxiosInstance.patch(endpoint, request.payload, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async fetchGetBannersPages(): Promise<AxiosResponse<BannerPageResponse[]>> {
        const endpoint = '/api/admin/banners/';
        return AxiosInstance.get<undefined,
        AxiosResponse <BannerPageResponse[]>>(endpoint, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

    public async fetchPutBannerItem(request: PutBannerItemRequest): Promise<AxiosResponse<string>> {
        const endpoint = `/api/admin/banners/${request.bannerId}`;
        return AxiosInstance.put<undefined,
        AxiosResponse <string>>(endpoint, request.payload, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });
    }

}
