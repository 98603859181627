import { Setting } from 'app/modules/settings/domain/entity/Setting';
import { SettingNameValue } from 'app/modules/settings/domain/valueObject/SettingName';
import { PutSettingItemRequest } from 'app/modules/settings/infrastructure/dto/request/PutSettingItemRequest';

export class PutSettingItemMapper {

    public static toDomain(setting: Setting): PutSettingItemRequest {
        switch (setting.name.value) {
            case SettingNameValue.BIG_RESERVE_UNIT:
                return { big_reserve_unit: setting.value.value };
            case SettingNameValue.BIG_RESERVE_M2:
                return { big_reserve_m2: setting.value.value };
            case SettingNameValue.STOCK_RESERVE_TIME:
                return { stock_reservation_time: setting.value.value };
            case SettingNameValue.STOCK_RESERVE_TIME_CHECKOUT:
                return { stock_reservation_time_checkout: setting.value.value };
            default:
                throw new Error('Invalid setting name to edit');
        }

    }

}
