import axios from 'axios';
import { TencerAuthServiceInstance } from '../../../tencer-routing/tencer-auth-route/tencer-auth-service';
import { Environment } from '../../../../../environment';
import { Refresh } from '../../../../login/infrastructure/api/web-service/refresh';
import { getToken } from '../../../helpers/config';

export const AxiosInstance = axios.create({
    baseURL: String(Environment.API_HOST),
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor
AxiosInstance.interceptors.request.use((config) => {
    // Do something before request is sent
    const newConfig = config;
    newConfig.headers = config.headers || {};
    newConfig.headers.Authorization = `Bearer ${getToken()}`;
    return newConfig;
}, (error) => {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
AxiosInstance.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.data.code === 401) {
        switch (error.response.data.message) {
            case 'Expired JWT Token':
                if (TencerAuthServiceInstance.hasRefreshToken()) {
                    await Refresh().then(() => {
                        // eslint-disable-next-line no-param-reassign,no-underscore-dangle
                        error.config._retry = false;
                    });
                }
                break;
            case 'JWT Token not found':
                TencerAuthServiceInstance.clear();
                window.location.reload();
                break;
            case 'Invalid JWT Token':
                if (TencerAuthServiceInstance.hasRefreshToken()) {
                    await Refresh();
                }
                break;
            case 'JWT Refresh Token Not Found':
                TencerAuthServiceInstance.clear();
                window.location.reload();
                break;
            case 'Invalid credentials':
                TencerAuthServiceInstance.clear();
                window.location.reload();
                break;
            default:
                return Promise.reject(error.response.data);
        }

        // eslint-disable-next-line no-param-reassign
        error.config.headers.Authorization = `Bearer ${getToken()}`;
        return AxiosInstance(error.config);
    }
    return Promise.reject(error.response.data);
});
