import { TranslationId } from 'app/modules/settings/domain/valueObject/TranslationId';
import { TranslationLanguage } from 'app/modules/settings/domain/valueObject/TranslationLanguage';
import { TranslationText } from 'app/modules/settings/domain/valueObject/TranslationText';

export class Translation {

    private readonly _id: TranslationId;
    private readonly _language: TranslationLanguage;
    private readonly _text: TranslationText;
    private readonly _link: URL|null;

    public constructor(
        id:TranslationId,
        language:TranslationLanguage,
        text:TranslationText,
        link:URL|null,

    ) {
        this._id = id;
        this._language = language;
        this._text = text;
        this._link = link;

    }

    public get id(): TranslationId {
        return this._id;
    }

    public get language(): TranslationLanguage {
        return this._language;
    }

    public get text(): TranslationText {
        return this._text;
    }

    public get link(): URL|null {
        return this._link;
    }

}
