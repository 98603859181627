import { SettingResponse } from 'app/modules/settings/infrastructure/dto/response/SettingResponse';
import { SettingsArray } from 'app/modules/settings/domain/entity/SettingsArray';
import { SettingMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/SettingMapper';

export class SettingsArrayMapper {

    public static toDomain(SettingsResponse: SettingResponse[]): SettingsArray {
        const settingArray: SettingsArray = new SettingsArray();

        SettingsResponse.map((setting) => {
            const settingDomain = SettingMapper.toDomain(setting);
            settingArray.addSetting(settingDomain);
            return true;
        });

        return settingArray;
    }

}
