import { LoginResponseDTO } from '../dto/login-reponse.DTO';
import { LoginRequestDTO } from '../dto/login-request.DTO';
import { LoginFormRegisters } from '../../../application/sign-in/sign-in.screen/container-sing-in/view-sign-in/login-form/login-form-registers';
import { Auth } from '../../../domain/auth';

export class LoginMapper {

    public static toDomain(loginResponseDto: LoginResponseDTO): Auth {
        const postLogin: Auth = Auth.buildEmpty();
        postLogin.setToken(loginResponseDto.token);
        postLogin.setRefreshToken(loginResponseDto.refresh_token);

        return postLogin;
    }

    public static toDTO(data:LoginFormRegisters): LoginRequestDTO {
        return {
            username: data.email,
            password: data.password,
        };
    }

}
