import {
    BIG_RESERVATION_M2,
    BIG_RESERVATION_UNIT,
    STOCK_RESERVATION_TIME,
    STOCK_RESERVATION_TIME_CHECKOUT,
} from 'i18n/translations/TR';
import i18next from 'i18next';

export enum SettingNameValue {
    BIG_RESERVE_UNIT= 'BIG_RESERVE_UNIT',
    BIG_RESERVE_M2= 'BIG_RESERVE_M2',
    STOCK_RESERVE_TIME= 'STOCK_RESERVE_TIME',
    STOCK_RESERVE_TIME_CHECKOUT= 'STOCK_RESERVE_TIME_CHECKOUT',
    CUT_OFF_HOUR= 'CUT_OFF_HOUR',
    CARRIER_PICKUP_BEFORE_CUT_OFF_HOUR= 'CARRIER_PICKUP_BEFORE_CUT_OFF_HOUR',
    CARRIER_PICKUP_AFTER_CUT_OFF_HOUR= 'CARRIER_PICKUP_AFTER_CUT_OFF_HOUR',
    CARRIER_INSURANCE= 'CARRIER_INSURANCE',
    PICK_HANDLE_PRICE= 'PICK_HANDLE_PRICE',
    PICK_HANDLE_ACCOUNT= 'PICK_HANDLE_ACCOUNT',
}

export class SettingName {

    constructor(private _value: SettingNameValue) {}

    get value(): SettingNameValue {
        return this._value;
    }

    get translate(): string {
        switch (this._value) {
            case SettingNameValue.BIG_RESERVE_UNIT:
                return i18next.t(BIG_RESERVATION_UNIT);
            case SettingNameValue.BIG_RESERVE_M2:
                return i18next.t(BIG_RESERVATION_M2);
            case SettingNameValue.STOCK_RESERVE_TIME:
                return i18next.t(STOCK_RESERVATION_TIME);
            case SettingNameValue.STOCK_RESERVE_TIME_CHECKOUT:
                return i18next.t(STOCK_RESERVATION_TIME_CHECKOUT);
            default:
                return '';
        }
    }

}
