import { AxiosResponse } from 'axios';
import { LoginRequestDTO } from '../dto/login-request.DTO';
import { LoginResponseDTO } from '../dto/login-reponse.DTO';
import { AxiosInstance } from '../../../../shared/infrastructure/api/web-service/axios-instance';
import { PasswordRecoveryRequestDTO } from '../dto/password-recovery-request.DTO';
import { refreshTokenRequestDTO } from '../dto/refresh-token-request.DTO';

class LoginRepository {

    private static INSTANCE = new LoginRepository();

    public static getInstance(): LoginRepository {
        return this.INSTANCE;
    }

    public async fetchLogin(postLoginRequestDto: LoginRequestDTO): Promise<AxiosResponse<LoginResponseDTO>> {
        const endpoint = '/api/login_check';
        return AxiosInstance.post<undefined,
        AxiosResponse < LoginResponseDTO >,
        LoginRequestDTO>(endpoint, postLoginRequestDto);
    }

    public async fetchRecoveryPassword(
        postPasswordRecoveryRequestDto: PasswordRecoveryRequestDTO,
    ): Promise<AxiosResponse<string>> {
        const endpoint = '/api/user/password/reset';
        return AxiosInstance.post<undefined,
        AxiosResponse <string>,
        PasswordRecoveryRequestDTO>(endpoint, postPasswordRecoveryRequestDto);
    }

    public async refreshToken(refreshToken: string): Promise<AxiosResponse<LoginResponseDTO>> {
        const endpoint = '/api/token/refresh';
        return AxiosInstance.post<undefined,
        AxiosResponse < LoginResponseDTO >,
        refreshTokenRequestDTO>(endpoint, {
            refresh_token: refreshToken,
        });
    }

}

export const LoginRepositoryInstance = LoginRepository.getInstance();
