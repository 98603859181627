import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ACCEPT, ERROR, SUCCESS } from '../../../../i18n/translations/TR';

interface AlertTemplateProps{
    options:{
        type:string
    },
    message:string,
    close:()=>void
    style:{
        offset:string
    }
}
export const AlertTemplate = (
    {
        style,
        message,
        close,
        options,
    }:AlertTemplateProps,
):React.ReactElement => {
    const { t } = useTranslation();
    const classesAlertTemplate = classNames(
        { 'alert alert-danger': options.type === 'error' },
        { 'alert alert-success': options.type === 'success' },
    );

    const classesButtonAlertTemplate = classNames(
        { 'btn-danger': options.type === 'error' },
        { 'btn-success': options.type === 'success' },
    );

    return (
        <div
            style={style}
            className={classesAlertTemplate}
        >
            <h4 className="alert-heading">
                {options.type === 'success' ? t(SUCCESS) : t(ERROR) }
            </h4>
            <div className="d-flex flex-row justify-content-between">
                {message}
            </div>
            <hr />
            <button
                onClick={close}
                type="button"
                className={classesButtonAlertTemplate}
            >
                {t(ACCEPT)}
            </button>
        </div>
    );
};
