import { TranslationArray } from 'app/modules/settings/domain/entity/TranslationArray';
import { TranslationResponse } from 'app/modules/settings/infrastructure/dto/response/TranslationResponse';
import { TranslationMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/TranslationMapper';

export class TranslationArrayMapper {

    public static toDomain(translationsResponse: TranslationResponse[]): TranslationArray {
        const translationArray: TranslationArray = new TranslationArray();

        translationsResponse.forEach((item) => {
            const translationDomain = TranslationMapper.toDomain(item);
            translationArray.addTranslation(translationDomain);
        });

        return translationArray;
    }

    public static fromDomain(translations: TranslationArray): TranslationResponse[] {
        const translationsDTO: TranslationResponse[] = [];

        translations.translations.forEach((item) => {
            translationsDTO.push(TranslationMapper.fromDomain(item));
        });

        return translationsDTO;
    }

}
