export const TR_CUATROOCHENTA = 'TR_CUATROOCHENTA';
export const WELCOME = 'WELCOME';
export const DESCRIPTION_LOGIN = 'DESCRIPTION_LOGIN';
export const LOGIN = 'LOGIN';
export const LOGIN_SENTENCE = 'LOGIN_SENTENCE';
export const CORREO = 'CORREO';
export const PASSWORD = 'PASSWORD';
export const PASSWORD_FORGOTTEN = 'PASSWORD_FORGOTTEN';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_TEXT = 'RECOVER_PASSWORD_TEXT';
export const SEND = 'SEND';
export const ERROR_FORM = 'ERROR_FORM';
export const PASSWORD_MESSAGE = 'PASSWORD_MESSAGE';
export const VIEW_ALL = 'VIEW_ALL';
export const TOP_TRENDING_TILES = 'TOP_TRENDING_TILES';
export const OUTLET = 'OUTLET';
export const ADD_TO_CART = 'ADD_TO_CART';
export const LINK_NAVBAR = 'LINK_NAVBAR';
export const LOG_OUT_NAVBAR = 'LOG_OUT_NAVBAR';
export const NOTIFICATIONS_NAVBAR = 'NOTIFICATIONS_NAVBAR';
export const TIMER_NAVBAR = 'TIMER_NAVBAR';
export const WOW_BANNER = 'WOW_BANNER';
export const DESIGN_BANNER = 'DESIGN_BANNER';
export const IN_BANNER = 'IN_BANNER';
export const WE_ALWAYS_GO_BANNER = 'WE_ALWAYS_GO_BANNER';
export const FOR_THE_BANNER = 'FOR_THE_BANNER';
export const CHECHK_STOCK_BANNER = 'CHECHK_STOCK_BANNER';
export const CHECK_THE_AVAILABLE_BANNER = 'CHECK_THE_AVAILABLE_BANNER';
export const BOOKING_BUY_BANNER = 'BOOKING_BUY_BANNER';
export const MAKE_THE_RESERVATION_BANNER = 'MAKE_THE_RESERVATION_BANNER';
export const SHIPPING_BANNER = 'SHIPPING_BANNER';
export const WE_TAKE_CARE_BANNER = 'WE_TAKE_CARE_BANNER';
export const OUR_COLLECTION_BANNER = 'OUR_COLLECTION_BANNER';
export const FEATURED_COLLECTIONS = 'FEATURED_COLLECTIONS';
export const DISCLAIMER_FOOTER = 'DISCLAIMER_FOOTER';
export const TERMS_CONDITIONS_FOOTER = 'TERMS_CONDITIONS_FOOTER';
export const PRIVACY_POLICY_FOOTER = 'PRIVACY_POLICY_FOOTER';
export const WE_CARE_ABOUT_FOOTER = 'WE_CARE_ABOUT_FOOTER';
export const CONTACT_US_FOOTER = 'CONTACT_US_FOOTER';
export const CONTACT_FOOTER = 'CONTACT_FOOTER';
export const ALL_RIGHTS_FOOTER = 'ALL_RIGHTS_FOOTER';
export const FACEBOOK_FOOTER = 'FACEBOOK_FOOTER';
export const LINKEDIN_FOOTER = 'LINKEDIN_FOOTER';
export const INSTAGRAM_FOOTER = 'INSTAGRAM_FOOTER';
export const PINTEREST_FOOTER = 'PINTEREST_FOOTER';
export const ENGLISH_FOOTER = 'ENGLISH_FOOTER';
export const SPANISH_FOOTER = 'SPANISH_FOOTER';
export const FRANCES_FOOTER = 'FRANCES_FOOTER';
export const DEUTSCHE_FOOTER = 'DEUTSCHE_FOOTER';
export const ITALIANO_FOOTER = 'ITALIANO_FOOTER';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const COLLECTION = 'COLLECTION';
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
export const CANCEL = 'CANCEL';
export const ERROR_FORM_VALIDATION = 'ERROR_FORM_VALIDATION';
export const SEARCH = 'SEARCH';
export const USUARIO = 'USUARIO';
export const CATALOG = 'CATALOG';
export const ORDERS = 'ORDERS';
export const STOCK_RESERVE = 'STOCK_RESERVE';
export const USERS = 'USERS';
export const SETTINGS = 'SETTINGS';
export const ADMIN = 'ADMIN';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const FILTER = 'FILTER';
export const ORDER_NO = 'ORDER_NO';
export const PO = 'PO';
export const CUSTOMER = 'CUSTOMER';
export const ADDRESS = 'ADDRESS';
export const DATE = 'DATE';
export const STATUS = 'STATUS';
export const AMOUNT = 'AMOUNT';
export const ATTACH = 'ATTACH';
export const TOTAL = 'TOTAL';
export const CUATROOCHENTA_ADDRESS = 'CUATROOCHENTA_ADDRESS';
export const DATE_FAKE = 'DATE_FAKE';
export const ORDER_STATUS = 'ORDER_STATUS';
export const ORDER_AMOUNT = 'ORDER_AMOUNT';
export const NEW_ORDER = 'NEW_ORDER';
export const WOW = 'WOW';
export const DESIGN = 'DESIGN';
export const NEW_USER = 'NEW_USER';
export const NEW_STOCK_RESERVE = 'NEW_STOCK_RESERVE';
export const ID = 'ID';
export const NAME = 'NAME';
export const CIF = 'CIF';
export const PHONE = 'PHONE';
export const MAIL = 'MAIL';
export const PAYMENT_CODE = 'PAYMENT_CODE';
export const AGENT_1 = 'AGENT_1';
export const AGENT_2 = 'AGENT_2';
export const COMISSION_1 = 'COMISSION_1';
export const COMISSION_2 = 'COMISSION_2';
export const COUNTRY = 'COUNTRY';
export const CREATE_USER = 'CREATE_USER';
export const EMAIL = 'EMAIL';
export const THIS_FIELD_IS_REQUIRED = 'THIS_FIELD_IS_REQUIRED';
export const YOU_MUST_ENTER_AN_EMAIL = 'YOU_MUST_ENTER_AN_EMAIL';
export const ROL = 'ROL';
export const CLIENT_ID = 'CLIENT_ID';
export const ORIGIN = 'ORIGIN';
export const LANGUAGE = 'LANGUAGE';
export const SELECT_OPTION = 'SELECT_OPTION';
export const ERROR_500 = 'ERROR_500';
export const REMOVE_USERS = 'REMOVE_USERS';
export const USERS_NOT_FOUND = 'USERS_NOT_FOUND';
export const SAVE = 'SAVE';
export const SAVING = 'SAVING';
export const MODIFY_USER = 'MODIFY_USER';
export const MODIFIED_USER = 'MODIFIED_USER';
export const USER_CREATE_INPUT_ERP_CODE_PLACEHOLDER = 'USER_CREATE_INPUT_ERP_CODE_PLACEHOLDER';
export const TOTAL_ELEMENTS = 'TOTAL_ELEMENTS';
export const USER_PERMISSIONS = 'USER_PERMISSIONS';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const ACCEPT = 'ACCEPT';
export const EDIT_USER_SUCCESSFULLY = 'EDIT_USER_SUCCESSFULLY';
export const ADD_USER_SUCCESSFULLY = 'ADD_USER_SUCCESSFULLY';
export const FILTER_BY_EMAIL = 'FILTER_BY_EMAIL';
export const ERROR_CODE_0 = 'ERROR_CODE_0';
export const ERROR_CODE_10000 = 'ERROR_CODE_10000';
export const USER_CREDENTIAL_PERMISSION_REQUIRED = 'USER_CREDENTIAL_PERMISSION_REQUIRED';
export const MANUAL = 'MANUAL';
export const AUTOMATIC = 'AUTOMATIC';
export const MODIFY_ORDER = 'MODIFY_ORDER';
export const REMOVE_ORDERS = 'REMOVE_ORDERS';
export const ORDER_UNLOCK_ACTION = 'ORDER_UNLOCK_ACTION';
export const ORDERS_LIST_BLOCK_COLUMN = 'ORDERS_LIST_BLOCK_COLUMN';
export const ORDERS_NOT_FOUND = 'ORDERS_NOT_FOUND';
export const EDIT_ORDER_TITLE = 'EDIT_ORDER_TITLE';
export const ORDER_SUMMARY = 'ORDER_SUMMARY';
export const TOTAL_PRODUCTS = 'TOTAL_PRODUCTS';
export const TOTAL_WEIGHT = 'TOTAL_WEIGHT';
export const TOTAL_AMOUNT = 'TOTAL_AMOUNT';
export const POUND_WEIGHT = 'POUND_WEIGHT';
export const WEIGHT_UNIT = 'WEIGHT_UNIT';
export const PALLETS = 'PALLETS';
export const BOXES = 'BOXES';
export const DISCOUNT = 'DISCOUNT';
export const BOX_UNIT = 'BOX_UNIT';
export const NET_TOTAL = 'NET_TOTAL';
export const DOLLAR = 'DOLLAR';
export const EURO = 'EURO';
export const ADD = 'ADD';
export const NO_AVAILABLE_STOCK_ADD_CART = 'NO_AVAILABLE_STOCK_ADD_CART';
export const PALLETS_SINGLE = 'PALLETS_SINGLE';
export const EDIT_LINE_FAIL = 'EDIT_LINE_FAIL';
export const EDIT_LINE = 'EDIT_LINE';
export const DELETE_LINE = 'DELETE_LINE';
export const DELETE_LINE_QUESTION = 'DELETE_LINE_QUESTION';
export const EDIT_LINE_QUESTION = 'EDIT_LINE_QUESTION';
export const PIECE_UNIT_SINGLE = 'PIECE_UNIT_SINGLE';
export const PIECE_UNIT = 'PIECE_UNIT';
export const M2_UNIT = 'M2_UNIT';
export const FEET2 = 'FEET2';
export const FEET2_UNIT_SINGLE = 'FEET2_UNIT_SINGLE';
export const ATTACHED_FILES = 'ATTACHED_FILES';
export const IN_CART = 'IN_CART';
export const NO_ATTACHED_FILES = 'NO_ATTACHED_FILES';
export const FOR_MANUFACTURING = 'FOR_MANUFACTURING';
export const BANNERS = 'BANNERS';
export const CART = 'CART';
export const HOME = 'HOME';
export const DESCRIPTION = 'DESCRIPTION';
export const SETTINGS_BANNER_TITLE = 'SETTINGS_BANNER_TITLE';
export const SETTINGS_CART_TITLE = 'SETTINGS_CART_TITLE';
export const SETTINGS_CART_ITEM_MAX_QUANTITY = 'SETTINGS_CART_ITEM_MAX_QUANTITY';
export const SETTINGS_CART_ITEM_MIN_QUANTITY = 'SETTINGS_CART_ITEM_MIN_QUANTITY';
export const BANNER_ITEM_EDIT_TITLE = 'BANNER_ITEM_EDIT_TITLE';
export const SPANISH_LANGUAGE = 'SPANISH_LANGUAGE';
export const ITALIAN_LANGUAGE = 'ITALIAN_LANGUAGE';
export const GERMAN_LANGUAGE = 'GERMAN_LANGUAGE';
export const FRENCH_LANGUAGE = 'FRENCH_LANGUAGE';
export const ENGLISH_LANGUAGE = 'ENGLISH_LANGUAGE';
export const BANNER_TEXT = 'BANNER_TEXT';
export const BIG_RESERVATION_UNIT = 'BIG_RESERVATION_UNIT';
export const BIG_RESERVATION_M2 = 'BIG_RESERVATION_M2';
export const STOCK_RESERVATION_TIME = 'STOCK_RESERVATION_TIME';
export const STOCK_RESERVATION_TIME_CHECKOUT = 'STOCK_RESERVATION_TIME_CHECKOUT';
export const CART_ITEM_EDIT_TITLE = 'CART_ITEM_EDIT_TITLE';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const REMOVE_FILTERS = 'REMOVE_FILTERS';
export const FILTERS = 'FILTERS';
export const ORDER_FILTER_LABEL_STATUS = 'ORDER_FILTER_LABEL_STATUS';
export const ORDER_FILTER_LABEL_PO = 'ORDER_FILTER_LABEL_PO';
export const INPUT_TYPE_TO_SEARCH = 'INPUT_TYPE_TO_SEARCH';
export const ORDER_FILTER_LABEL_DATE_FROM = 'ORDER_FILTER_LABEL_DATE_FROM';
export const ORDER_FILTER_LABEL_DATE_TO = 'ORDER_FILTER_LABEL_DATE_TO';
export const DEFAULT_FILTERS = 'DEFAULT_FILTERS';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const ACCESS_DENIED_INFO = 'ACCESS_DENIED_INFO';
export const BANNER_LINK_LABEL = 'BANNER_LINK_LABEL';
export const USER_DELETE_CONFIRM_TITLE = 'USER_DELETE_CONFIRM_TITLE';
export const USER_DELETE_CONFIRM_BODY = 'USER_DELETE_CONFIRM_BODY';
export const BLOCKED_ORDERS_FILTER_LABEL = 'BLOCKED_ORDERS_FILTER_LABEL';
export const BLOCKED_ORDERS_FILTER_INFO = 'BLOCKED_ORDERS_FILTER_INFO';
export const ACTIVE = 'ACTIVE';
export const ADD_ORDER_TITLE = 'ADD_ORDER_TITLE';
export const PRODUCT_LABEL = 'PRODUCT_LABEL';
export const LOT_LABEL = 'LOT_LABEL';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const PRODUCT_LINE_REF_LABEL = 'PRODUCT_LINE_REF_LABEL';
export const LOADING = 'LOADING';
export const DISPLAY_ADDITIONAL_RESULTS = 'DISPLAY_ADDITIONAL_RESULTS';
export const NO_MATCHES_FOUND = 'NO_MATCHES_FOUND';
export const AVAILABLE = 'AVAILABLE';
export const SEARCHING = 'SEARCHING';
export const UNIT_LABEL = 'UNIT_LABEL';
export const SELECT_LOT = 'SELECT_LOT';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const SEND_LABEL = 'SEND_LABEL';
export const NO_PRODUCTS_ADDED_MESSAGE = 'NO_PRODUCTS_ADDED_MESSAGE';
export const CM_UNIT = 'CM_UNIT';
export const SENDING_LABEL = 'SENDING_LABEL';
export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CONTINUE_ORDER = 'CONTINUE_ORDER';
export const CART_IN_PROGRESS = 'CART_IN_PROGRESS';
export const CART_IN_PROGRESS_INFO = 'CART_IN_PROGRESS_INFO';
export const M2 = 'M2';
export const Unit_one = 'Unit_one';
export const Unit_other = 'Unit_other';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_QUESTION = 'DELETE_ORDER_QUESTION';
export const AN_ERROR_HAS_OCCURRED = 'AN_ERROR_HAS_OCCURRED';
export const DELETING_ORDER_WITH = 'DELETING_ORDER_WITH';
export const ERROR_CODE_40014 = 'ERROR_CODE_40014';
export const ERROR_CODE_40015 = 'ERROR_CODE_40015';
export const DOWNLOAD_PROFORMA = 'DOWNLOAD_PROFORMA';
export const PO_LABEL = 'PO_LABEL';
export const ORDER_ATTACH_FILE_INPUT_LABEL = 'ORDER_ATTACH_FILE_INPUT_LABEL';
export const ORDER_ATTACH_FILE = 'ORDER_ATTACH_FILE';
export const TRANSPORT = 'TRANSPORT';
export const SETTINGS_TRANSPORT_TITLE = 'SETTINGS_TRANSPORT_TITLE';
export const CARRIER_PICKUP = 'CARRIER_PICKUP';
export const HOURS_BEFORE_CUT_OFF_HOUR = 'HOURS_BEFORE_CUT_OFF_HOUR';
export const HOURS_AFTER_CUT_OFF_HOUR = 'HOURS_AFTER_CUT_OFF_HOUR';
export const CARRIER_PICKUP_INSTRUCTIONS = 'CARRIER_PICKUP_INSTRUCTIONS';
export const HOURS_MUST_BE_GREATER_THAN_0 = 'HOURS_MUST_BE_GREATER_THAN_0';
export const HOURS_MUST_BE_INTEGERS = 'HOURS_MUST_BE_INTEGERS';
export const CUT_OFF_HOUR = 'CUT_OFF_HOUR';
export const HOUR_MUST_BE_BETWEEN_1_AND_24 = 'HOUR_MUST_BE_BETWEEN_1_AND_24';
export const PICK_HANDLE = 'PICK_HANDLE';
export const PRICE_MUST_BE_GREATER_THAN_0 = 'PRICE_MUST_BE_GREATER_THAN_0';
export const PICK_HANDLE_PRICE = 'PICK_HANDLE_PRICE';
export const PICK_HANDLE_ACCOUNT = 'PICK_HANDLE_ACCOUNT';
export const PICK_HANDLE_ACCOUNT_MUST_BE_INTEGER = 'PICK_HANDLE_ACCOUNT_MUST_BE_INTEGER';
export const PICK_HANDLE_ACCOUNT_MUST_BE_GREATER_THAN_0 = 'PICK_HANDLE_ACCOUNT_MUST_BE_GREATER_THAN_0';
export const PICK_HANDLE_PRICE_MUST_BE_INTEGER = 'PICK_HANDLE_PRICE_MUST_BE_INTEGER';
export const CARRIER_INSURANCE = 'CARRIER_INSURANCE';
export const INSURANCE_MUST_BE_INTEGERS = 'INSURANCE_MUST_BE_INTEGERS';
export const WAREHOUSE = 'WAREHOUSE';
export const WAREHOUSE_TRANSPORT_TITLE = 'WAREHOUSE_TRANSPORT_TITLE';
export const WAREHOUSE_CONTACT = 'WAREHOUSE_CONTACT';
export const WAREHOUSE_CONTACT_NAME_LENGTH_ERROR = 'WAREHOUSE_CONTACT_NAME_LENGTH_ERROR';
