import { Translation } from 'app/modules/settings/domain/entity/Translation';

export class TranslationArray {

    private _translations: Translation[];

    public constructor() {
        this._translations = [];
    }

    get translations(): Translation[] {
        return this._translations;
    }

    public setTranslations(value: Translation[]): void {
        this._translations = value;
    }

    public addTranslation(value: Translation): void {
        this._translations?.push(value);
    }

    public addTranslations(values: Translation[]): void {
        values.map((translation):void => this.addTranslation(translation));
    }

    public getLength(): number {
        return this._translations?.length;
    }

    public getTranslation(index:number):Translation | null {
        return this._translations[index];
    }

}
