class TencerAuthService {

    private static readonly TOKEN = 'token';
    private static readonly REFRESH_TOKEN = 'refresh_token';
    private static readonly ORIGIN = 'origin';

    private static INSTANCE = new TencerAuthService();

    private _nextRoute: string;

    private constructor() {
        this._nextRoute = '';
    }

    public static getInstance(): TencerAuthService {
        return TencerAuthService.INSTANCE;
    }

    public authenticate(token: string): void {
        window.localStorage.setItem(TencerAuthService.TOKEN, token);
    }

    public authenticateRefreshToken(refresh_token: string): void {
        window.localStorage.setItem(TencerAuthService.REFRESH_TOKEN, refresh_token);
    }

    public authenticateOrigin(origin: string): void {
        window.localStorage.setItem(TencerAuthService.ORIGIN, origin);
    }

    public isAuthenticated(): boolean {
        return !!window.localStorage.getItem(TencerAuthService.TOKEN);
    }

    public hasRefreshToken(): boolean {
        return !!window.localStorage.getItem(TencerAuthService.REFRESH_TOKEN);
    }

    public clear():void {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        return window.localStorage.clear();
    }

    public saveNextRoute(nextRoute: string): void {
        this._nextRoute = nextRoute;
    }

    public nextRoute(): string {
        return this._nextRoute;
    }

}

export const TencerAuthServiceInstance = TencerAuthService.getInstance();
