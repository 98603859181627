import React from 'react';
import 'i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import { DependencyInjectionProvider } from 'app/shared/view/context/DependecyInjectionContext';
import { AppRouting } from './shared/tencer-routing/app-routing';
import { AlertTemplate } from './shared/components/CO-alert-template/CO-alert-template';

export const App = (): React.ReactElement => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: Infinity,
            },
        },
    });
    const optionsReactAlert = {
        // you can also just use 'bottom center'
        position: positions.TOP_CENTER,
        offset: '30px',
        // you can also just use 'scale'
        transition: transitions.SCALE,
    };

    return (
        <DependencyInjectionProvider>
            <AlertProvider template={AlertTemplate} {...optionsReactAlert}>
                <QueryClientProvider client={queryClient}>
                    <AppRouting />
                </QueryClientProvider>
            </AlertProvider>
        </DependencyInjectionProvider>
    );
};
