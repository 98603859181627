import { AxiosRequestConfig } from 'axios';

export const tokenConfig = (): AxiosRequestConfig => {
    const tokenUser = localStorage.getItem('token');

    let config = {};

    if (tokenUser) {
        const newToken = tokenUser.replace(/['"]+/g, '');
        config = {
            headers: { 'content-type': 'application/json',
                Authorization: `Bearer ${newToken}` },
        };
    } else {

        config = {
            headers: { 'content-type': 'application/json' },
        };
    }

    return config;

};

export const config = {
    headers: { 'content-type': 'application/json' },
};

/* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
export const url = (base: string, api: string, token: any = '') : string => {
    return base + api + token;
};

export const getToken = ():string => {
    let tokenUser = localStorage.getItem('token');
    if (tokenUser) {
        tokenUser = tokenUser.replace(/['"]+/g, '');
        return tokenUser;
    }

    return '';
};
