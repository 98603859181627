import React, { createContext, ReactElement, useMemo } from 'react';
import { AxiosTencerAdminApiClient } from 'app/shared/infrastructure/api/AxiosTencerAdminApiClient';
import { ApiSettingsRepository } from 'app/modules/settings/infrastructure/repository/api/ApiSettingsRepository';
import { SettingsRepository } from 'app/modules/settings/domain/repository/SettingsRepository';
import { BannerRepository } from 'app/modules/settings/domain/repository/BannerRepository';
import { ApiBannerRepository } from 'app/modules/settings/infrastructure/repository/api/ApiBannerRepository';

export interface IDependencyInjection {
    settingsRepository: SettingsRepository,
    bannerRepository: BannerRepository
}

export const DependencyInjectionContext = createContext<IDependencyInjection>(
    {} as IDependencyInjection,
);

type ProviderProps = {
    children: ReactElement,
};

export const DependencyInjectionProvider = ({ children }: ProviderProps): ReactElement => {
    const tencerAdminApiClient = new AxiosTencerAdminApiClient();

    const stateValues = useMemo(() => {
        const settingsRepository = new ApiSettingsRepository(tencerAdminApiClient);
        const bannerRepository = new ApiBannerRepository(tencerAdminApiClient);

        return {
            settingsRepository,
            bannerRepository,
        };
    }, []);

    return (
        <DependencyInjectionContext.Provider value={stateValues}>
            {children}
        </DependencyInjectionContext.Provider>
    );
};
