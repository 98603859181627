import { Id } from 'app/shared/domain/value-objects/id';
import { SettingOrigin } from 'app/modules/settings/domain/valueObject/SettingOrigin';
import { SettingName } from 'app/modules/settings/domain/valueObject/SettingName';
import { SettingValue } from 'app/modules/settings/domain/valueObject/SettingValue';
import { SettingId } from 'app/modules/settings/domain/valueObject/SettingId';

export class Setting {

    private readonly _id: SettingId;
    private readonly _origin: SettingOrigin;
    private readonly _name: SettingName;
    private readonly _value: SettingValue;

    public constructor(
        id:Id,
        origin:SettingOrigin,
        name:SettingName,
        value:SettingValue,
    ) {
        this._id = id;
        this._origin = origin;
        this._name = name;
        this._value = value;
    }

    public get id(): Id {
        return this._id;
    }

    public get origin(): SettingOrigin {
        return this._origin;
    }

    public get name(): SettingName {
        return this._name;
    }

    public get value(): SettingValue {
        return this._value;
    }

}
