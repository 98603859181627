import { BannerPageResponse } from 'app/modules/settings/infrastructure/dto/response/BannerPageResponse';
import { BannerPageArray } from 'app/modules/settings/domain/valueObject/BannerPageArray';
import { BannerPageMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/BannerPageMapper';

export class BannerPageArrayMapper {

    public static toDomain(bannerPagesResponse: BannerPageResponse[]): BannerPageArray {
        const bannerPageArray: BannerPageArray = new BannerPageArray();

        bannerPagesResponse.forEach((item) => {
            const bannerPageDomain = BannerPageMapper.toDomain(item);
            bannerPageArray.addBannerPage(bannerPageDomain);
        });

        return bannerPageArray;
    }

}
