export class Token {

    token;

    public constructor(value: string) {
        this.ensureIsNotUndefined(value);
        this.token = value;
    }

    private ensureIsNotUndefined(value: string): void {
        if (!value) {
            throw new Error('Token cannot be undefined');
        }
    }

}
