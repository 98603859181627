import { AxiosTencerAdminApiClient } from 'app/shared/infrastructure/api/AxiosTencerAdminApiClient';
import { BannerRepository } from 'app/modules/settings/domain/repository/BannerRepository';
import { BannerPageArrayMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/BannerPageArrayMapper';
import { BannerPageArray } from 'app/modules/settings/domain/valueObject/BannerPageArray';
import { BannerPageId } from 'app/modules/settings/domain/valueObject/BannerPageId';
import { TranslationArrayMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/TranslationArrayMapper';
import { TranslationArray } from 'app/modules/settings/domain/entity/TranslationArray';

export class ApiBannerRepository implements BannerRepository {

    constructor(private _apiClient: AxiosTencerAdminApiClient) {}

    public async findAll(): Promise<BannerPageArray> {
        const data = await this._apiClient.fetchGetBannersPages();

        return BannerPageArrayMapper.toDomain(data.data);
    }

    public async add(id: BannerPageId, translations: TranslationArray): Promise<string> {
        const result = await this._apiClient.fetchPutBannerItem({
            bannerId: id.value,
            payload: {
                translations: TranslationArrayMapper.fromDomain(translations),
            },
        });

        return result.data;
    }

}
