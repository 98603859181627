import { SettingsRepository } from 'app/modules/settings/domain/repository/SettingsRepository';
import { AxiosTencerAdminApiClient } from 'app/shared/infrastructure/api/AxiosTencerAdminApiClient';
import { SettingName } from 'app/modules/settings/domain/valueObject/SettingName';
import { SettingsArrayMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/SettingsArrayMapper';
import { SettingsArray } from 'app/modules/settings/domain/entity/SettingsArray';
import { Setting } from 'app/modules/settings/domain/entity/Setting';
import { Price } from 'app/modules/orders/domain/value-objects/price';
import { PutSettingItemMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/PutSettingItemMapper';
import { WarehouseContact } from 'app/modules/settings/domain/entity/WarehouseContact';
import { WarehouseContactMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/WarehouseContactMapper';

export class ApiSettingsRepository implements SettingsRepository {

    constructor(private _apiClient: AxiosTencerAdminApiClient) {}

    public async findAll(): Promise<SettingsArray> {
        const data = await this._apiClient.getSettings();

        return SettingsArrayMapper.toDomain(data.data);
    }

    public async findByName(name:SettingName): Promise<Setting> {
        const settings = await this.findAll();
        const settingByName = settings.settings.find((setting) => setting.name.value === name.value);

        if (!settingByName) {
            throw new Error('Setting can not be undefined');
        }
        return settingByName;
    }

    public async findAllWarehouseContacts(): Promise<WarehouseContact[]> {
        const data = await this._apiClient.getWarehouseContacts();

        return data.data.map(WarehouseContactMapper.toDomain);
    }

    public async add(setting: Setting): Promise<string> {
        const result = await this._apiClient.putSettingItem(
            PutSettingItemMapper.toDomain(setting),
        );

        return result.data;
    }

    public async patchShipmentAppointment(
        insurance: number,
        cutOffHour: number,
        hoursAfterCutOffHour: number,
        hoursBeforeCutOffHour: number,
    ): Promise<void> {
        const result = await this._apiClient.patchShipmentAppointment({
            insurance,
            cut_off_hour: cutOffHour,
            hours_after_cut_off_hour: hoursAfterCutOffHour,
            hours_before_cut_off_hour: hoursBeforeCutOffHour,
        });

        return result.data;
    }

    public async patchPickHandle(
        account: number,
        price: Price,
    ): Promise<void> {
        const result = await this._apiClient.patchPickHandle({
            price: price.value,
            account,
        });

        return result.data;
    }

    public async patchWarehouseContact(warehouseContact: WarehouseContact): Promise<void> {
        const result = await this._apiClient.patchWarehouseContact({
            code: warehouseContact.code.value,
            payload: {
                name: warehouseContact.name,
                phone_number: warehouseContact.phone.value,
            },
        });

        return result.data;
    }

}
