export class RefreshToken {

    refreshToken;

    public constructor(value: string) {
        this.ensureIsNotUndefined(value);
        this.refreshToken = value;
    }

    private ensureIsNotUndefined(value: string): void {
        if (!value) {
            throw new Error('RefreshToken cannot be undefined');
        }
    }

}
