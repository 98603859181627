import { WarehouseContactResponse } from 'app/modules/settings/infrastructure/dto/response/WarehouseContactResponse';
import { WarehouseContact } from 'app/modules/settings/domain/entity/WarehouseContact';
import { WarehouseContactCode } from 'app/modules/settings/domain/valueObject/WarehouseContactCode';
import { Phone } from 'app/modules/user/domain/value-objects/phone';

export class WarehouseContactMapper {

    public static toDomain(warehouseContactResponse: WarehouseContactResponse): WarehouseContact {
        return new WarehouseContact(
            new WarehouseContactCode(warehouseContactResponse.code),
            warehouseContactResponse.name,
            new Phone(warehouseContactResponse.phone_number),
        );
    }

}
