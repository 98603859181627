import { Token } from './value-objects/token';
import { RefreshToken } from './value-objects/refresh-token';

export class Auth {

    private _token?: Token;
    private _refreshToken?: RefreshToken;

    private constructor() {
    }

    public static buildEmpty(): Auth {
        return new Auth();
    }

    get getToken(): string | undefined {
        return this._token?.token;
    }

    public setToken(value: string): void {
        this._token = new Token(value);
    }

    get getRefreshToken(): string | undefined {
        return this._refreshToken?.refreshToken;
    }

    public setRefreshToken(value: string): void {
        this._refreshToken = new RefreshToken(value);
    }

}
