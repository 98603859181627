import packageData from './package.base.json';

export class Environment {

    public static APP_VERSION = packageData.version;
    public static API_HOST: string = (():string => {
        if (process.env.REACT_APP_ENV === 'stage') {
            return 'https://wow-stage-api.cuatroochenta.com';
        }
        if (process.env.REACT_APP_ENV === 'prod') {
            return 'https://api.customerswow.com';
        }

        return 'http://localhost:8080';
    })();

    public static API_URL: string = (():string => {
        if (process.env.REACT_APP_ENV === 'stage') {
            return ' https://wow-stage.cuatroochenta.com/';
        }
        if (process.env.REACT_APP_ENV === 'prod') {
            return 'https://customerswow.com/';
        }

        return 'http://localhost:8080';
    })();
    public static API_TIMEOUT = 50000;

}
