export class Integer {

    private readonly _value: number;

    public constructor(value: number) {
        this.ensureIsInt(value);
        this._value = value;
    }

    private ensureIsInt(value: number): void {
        if (value === undefined) {
            throw new Error('Integer cannot be undefined');
        }
        if (!Number.isInteger(value)) {
            throw new Error('Integer must be an integer');
        }
    }

    public get value(): number {
        return this._value;
    }

}
