import { TranslationResponse } from 'app/modules/settings/infrastructure/dto/response/TranslationResponse';

export interface BannerPageResponse {
    id:string
    origin:number
    page_id:BannerPageNameResponse
    translations: TranslationResponse[]
}

export enum BannerPageNameResponse {
    CART = 'cart',
    OUTLET = 'outlet'
}
