export class TencerRoutes {

    public static LOGIN = '/login/*';
    public static LOGIN_SIGN_IN = '/login/sign-in';
    public static LOGIN_SIGN_UP = '/login/sign-up';

    public static HOME = '/*';
    public static USERS = '/user/list';
    public static USER_EDIT = '/user/edit';
    public static USER_CREATE = '/user/create';
    public static ORDERS = '/order/list';
    public static ORDER_EDIT = '/order/edit/:orderId';
    public static ORDER_ADD = '/order/add';
    public static BANNERS_SETTINGS = '/settings/banner';
    public static CART_SETTINGS = '/settings/cart';
    public static TRANSPORT_SETTINGS = '/settings/transport';
    public static WAREHOUSE_SETTINGS = '/settings/warehouse';

}
