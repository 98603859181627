import { Phone } from 'app/modules/user/domain/value-objects/phone';
import { WarehouseContactCode } from 'app/modules/settings/domain/valueObject/WarehouseContactCode';

export class WarehouseContact {

    private readonly _code: WarehouseContactCode;
    private readonly _name: string;
    private readonly _phone: Phone;

    public constructor(
        code: WarehouseContactCode,
        name: string,
        phone: Phone,
    ) {
        this._code = code;
        this._name = name;
        this._phone = phone;
    }

    public get code(): WarehouseContactCode {
        return this._code;
    }

    public get name(): string {
        return this._name;
    }

    public get phone(): Phone {
        return this._phone;
    }

}
