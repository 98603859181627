export enum BannerPageNameValue {
    CART = 'cart',
    OUTLET = 'outlet'
}

export class BannerPageName {

    private readonly _value: BannerPageNameValue;

    public constructor(value: BannerPageNameValue) {
        this.ensureIsNotUndefined(value);
        this._value = value;
    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined) {
            throw new Error('BannerPageName cannot be undefined');
        }
    }

    public get value(): BannerPageNameValue {
        return this._value;
    }

}
