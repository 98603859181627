import { Id } from 'app/shared/domain/value-objects/id';
import { SettingResponse, SettingNameResponse } from 'app/modules/settings/infrastructure/dto/response/SettingResponse';
import { Setting } from 'app/modules/settings/domain/entity/Setting';
import { SettingOrigin } from 'app/modules/settings/domain/valueObject/SettingOrigin';
import { SettingName, SettingNameValue } from 'app/modules/settings/domain/valueObject/SettingName';
import { SettingValue } from 'app/modules/settings/domain/valueObject/SettingValue';

export class SettingMapper {

    private static _settingsNameToDomain(name: SettingNameResponse): SettingName {
        switch (name) {
            case SettingNameResponse.BIG_RESERVE_UNIT:
                return new SettingName(SettingNameValue.BIG_RESERVE_UNIT);
            case SettingNameResponse.BIG_RESERVE_M2:
                return new SettingName(SettingNameValue.BIG_RESERVE_M2);
            case SettingNameResponse.STOCK_RESERVATION_TIME:
                return new SettingName(SettingNameValue.STOCK_RESERVE_TIME);
            case SettingNameResponse.STOCK_RESERVATION_TIME_CHECKOUT:
                return new SettingName(SettingNameValue.STOCK_RESERVE_TIME_CHECKOUT);
            case SettingNameResponse.CARRIER_PICKUP_BEFORE_CUT_OFF_HOUR:
                return new SettingName(SettingNameValue.CARRIER_PICKUP_BEFORE_CUT_OFF_HOUR);
            case SettingNameResponse.CARRIER_PICKUP_AFTER_CUT_OFF_HOUR:
                return new SettingName(SettingNameValue.CARRIER_PICKUP_AFTER_CUT_OFF_HOUR);
            case SettingNameResponse.CARRIER_INSURANCE:
                return new SettingName(SettingNameValue.CARRIER_INSURANCE);
            case SettingNameResponse.CUT_OFF_HOUR:
                return new SettingName(SettingNameValue.CUT_OFF_HOUR);
            case SettingNameResponse.PICK_HANDLE_PRICE:
                return new SettingName(SettingNameValue.PICK_HANDLE_PRICE);
            case SettingNameResponse.PICK_HANDLE_ACCOUNT:
                return new SettingName(SettingNameValue.PICK_HANDLE_ACCOUNT);
            default:
                throw new Error('Invalid SettingName');
        }
    }

    public static toDomain(settingResponse: SettingResponse):Setting {
        return new Setting(
            new Id(settingResponse.id),
            new SettingOrigin(settingResponse.origin),
            this._settingsNameToDomain(settingResponse.name),
            new SettingValue(settingResponse.value),
        );

    }

}
