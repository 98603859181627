import { BannerPageResponse, BannerPageNameResponse } from 'app/modules/settings/infrastructure/dto/response/BannerPageResponse';
import { BannerPage } from 'app/modules/settings/domain/entity/BannerPage';
import { BannerPageName, BannerPageNameValue } from 'app/modules/settings/domain/valueObject/BannerPageName';
import { BannerPageOrigin } from 'app/modules/settings/domain/valueObject/BannerPageOrigin';
import { BannerPageId } from 'app/modules/settings/domain/valueObject/BannerPageId';
import { TranslationArrayMapper } from 'app/modules/settings/infrastructure/repository/api/dataMapper/TranslationArrayMapper';

export class BannerPageMapper {

    private static _fromPageName(pageNameResponse: BannerPageNameResponse): BannerPageName {
        switch (pageNameResponse) {
            case BannerPageNameResponse.CART:
                return new BannerPageName(BannerPageNameValue.CART);
            case BannerPageNameResponse.OUTLET:
                return new BannerPageName(BannerPageNameValue.OUTLET);
            default:
                throw new Error('Invalid BannerPageName');
        }
    }

    public static toDomain(bannerPageResponse: BannerPageResponse):BannerPage {
        return new BannerPage(
            new BannerPageId(bannerPageResponse.id),
            new BannerPageOrigin(bannerPageResponse.origin),
            this._fromPageName(bannerPageResponse.page_id),
            TranslationArrayMapper.toDomain(bannerPageResponse.translations),
        );

    }

}
